import { useState, useEffect, useRef, useCallback } from 'react';
import { Spinner, Row, Col } from 'react-bootstrap';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { isMobile } from 'react-device-detect';

// components
import Card from 'components/Landing/Card';

// utils
import { lereroFetch } from 'utils/fetch';
import { useWhitelabelContext } from 'context/whitelabel.context';
import Button from 'components/Button';
import { BUTTON_TYPE } from 'constants/button';
import { Heading, SubHeading, TextBody } from 'components/Typography/General';
import { FONT_TYPES } from 'variables/font_groups';
import { capitalizedString } from 'utils/string_capitalize';

const initialTabsData = [
  {
    title: 'Courses',
    pathname: '/',
    identifier: null,
    baseRoute: 'courses',
  },
  {
    title: 'Products',
    pathname: '/?show=products',
    identifier: 'products',
    baseRoute: 'products',
  },
  {
    title: 'Bundles',
    pathname: '/?show=bundles',
    identifier: 'bundles',
    baseRoute: 'bundles',
  },
];

export default function Course() {
  const Router = useRouter();
  const buttonRef = useRef(null);

  const [listData, setListData] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Function for handling data fetching of course or product item in home page
   * @param {string} dataType
   */
  const handleLoadData = async (dataType = 'courses') => {
    setIsLoading(true);
    const response = await lereroFetch(
      'GET',
      `/${dataType}?page=1&limit=6${dataType === 'courses' ? '' : '&sort=newest'}`
    );
    if (response.success) {
      setListData(response.data?.data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Router.isReady) {
      const urlParams = new URLSearchParams(window.location.search);

      // if url have params 'show', means it is showing product item
      const productParams = urlParams.get('show');
      setActiveTab(productParams);
      let dataType = 'courses';
      if (productParams) {
        const dataItem = initialTabsData.find((item) => item.identifier === productParams);
        if (dataItem) {
          dataType = dataItem.baseRoute;
        }
      }

      handleLoadData(dataType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router.isReady, window.location.search]);

  const getActiveTabItemName = useCallback(
    (shouldCapitalize = false) => {
      let ret;

      switch (activeTab) {
        case 'products':
        case 'bundles':
          ret = activeTab;
          break;
        default:
          ret = 'courses';
          break;
      }

      if (shouldCapitalize) {
        ret = capitalizedString(ret);
      }

      return ret;
    },
    [activeTab]
  );

  return (
    <div className="course-wrapper">
      {isLoading ? (
        <div className="loading">
          <Spinner animation="border" variant="white" style={{ width: '50px', height: '50px' }} />
        </div>
      ) : (
        ''
      )}

      {/* Tabs Section */}
      <div className="button-container">
        {initialTabsData.map((tabsItem) => {
          return (
            <Button
              ref={buttonRef}
              key={tabsItem.identifier}
              fontType={FONT_TYPES.subHeading}
              variety={isMobile ? 4 : 5}
              onClick={() => {
                window.scrollTo({
                  top: buttonRef.current?.getBoundingClientRect().top + window.scrollY - 120,
                  behavior: 'smooth',
                });
                Router.push(tabsItem.pathname, undefined, { scroll: false });
              }}
              type={activeTab === tabsItem.identifier ? BUTTON_TYPE.primary : BUTTON_TYPE.disabled}
            >
              {tabsItem.title}
            </Button>
          );
        })}
      </div>

      {/* Title Section */}
      <div className="course-title-container">
        <div className="course-title-left">
          <Heading variety={isMobile ? 4 : 3} tag="h4" className="course-title mb-3">
            {getActiveTabItemName(true)}
          </Heading>
          <TextBody variety={isMobile ? 4 : 2} className="course-sub">
            Showing new available {getActiveTabItemName()}
          </TextBody>
        </div>
        <div className="course-title-right">
          <Link
            passHref
            href={`/category?${
              activeTab === 'products' ? 'products=all' : activeTab === 'bundles' ? 'bundles=all' : 'category=all'
            } `}
          >
            <SubHeading tag="a" variety={isMobile ? 5 : 3} className="course-sub is-link text-decoration-none">
              See all available {getActiveTabItemName()} {'>'}
            </SubHeading>
          </Link>
        </div>
      </div>

      {/* Card Section */}
      <Row className="g-5 mt-1">
        {listData.map((item, index) => {
          return item ? (
            <Col key={index} lg="4" md="6" xs="12">
              <Card
                activeTabItemName={getActiveTabItemName()}
                isProduct={activeTab === 'products'}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                itemType={activeTab}
                item={item}
                language={item.language}
              />
            </Col>
          ) : null;
        })}
      </Row>
    </div>
  );
}
