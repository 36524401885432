import { Row, Col } from 'react-bootstrap';
import Head from 'next/head';
import { Heading } from 'components/Typography/General';

import { isMobile } from 'react-device-detect';
export default function Hero() {
  return (
    <div className="hero-wrapper">
      <Head>
        <title>Marketplace</title>
      </Head>
      <Row className="align-items-center flex-column-reverse flex-lg-row mx-0">
        <Col lg="6" xs="12" className="p-5 p-md-0">
          <Heading variety={isMobile ? 4 : 2} tag="h2" className="hero-sub">
            Marketplace
          </Heading>

          <Heading variety={isMobile ? 3 : 1} tag="h1" className="hero-text">
            Why stop learning when you can learn as much as you can.
          </Heading>
        </Col>
        <Col lg="6" xs="12">
          <div className="hero-image">
            <div className="hero-recorded-section d-none d-md-block">
              <img className="hero-recorded-section-icon" src="/landing/hero/icon_checklist.svg" alt="checklist" />
              <p className="hero-recorded-section-sub">Recorded Courses</p>
              <p className="hero-recorded-section-text">Over 5 Hours</p>
              <img className="hero-recorded-section-image" src="/landing/hero/icon_microphone.gif" alt="microphone" />
            </div>
            <div className="hero-course-section d-none d-md-flex">
              <img className="hero-course-section-image" src="/landing/hero/icon_book.svg" alt="book" />
              <div>
                <span className="hero-course-section-sub">Over 50</span>
                <br />
                <span className="hero-course-section-text">Active Courses</span>
              </div>
            </div>
            <img src="/landing/hero/image.svg" className="d-none d-md-flex" alt="image" />
            <img src="/landing/hero/image_mobile.svg" className="d-md-none d-flex w-100" alt="image" />
          </div>
        </Col>
      </Row>
    </div>
  );
}
